import React from "react";
import "./footer.scss";
import logo from "../../assets/img/logo1.svg";
import mail from "../../assets/img/mail.png";

export const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="mt-auto">
      <div className="container">
        <div className="row py-5">
          <div className="col-lg-4 col-12 mb-3">
            <img src={logo} alt="" className="footer-logo" />
          </div>
          <div className="col-lg-2 col-md-4 col-6 mb-3">
            <p className="font16 font-medium text-black mb-2">Sitemap</p>
            <p className="mb-1">
              <a href="/home">Home</a>
            </p>
            <p className="mb-1">
              <a href="/about">About</a>
            </p>
            <p className="mb-1">
              <a href="/how-it-works">How It Works</a>
            </p>
            {/* <p className="mb-1">
              <a href="/pricing">Pricing</a>
            </p>
            <p className="mb-1">
              <a href="/faq">FAQ</a>
            </p> */}
          </div>
          <div className="col-lg-2 col-md-4 col-6 mb-3">
            <p className="font16 font-medium text-black mb-2">Socials</p>
            <p className="mb-1">
              <a href="https://www.facebook.com/lwb" target="_blank">
                Facebook
              </a>
            </p>
            <p className="mb-1">
              <a href="https://www.linkedin.com/lwb" target="_blank">
                Linkedin
              </a>
            </p>
            <p className="mb-1">
              <a href="https://www.instagram.com/lwb" target="_blank">
                Instagram
              </a>
            </p>
            <p className="mb-1">
              <a href="https://www.twitter.com/lwb" target="_blank">
                Twitter
              </a>
            </p>
          </div>

          <div className="col-lg-4 col-md-4 col-8 mb-3">
            <div className="mb-4">
              <p className="font16 font-medium text-black mb-2">Head Office</p>
              <p>
                Xilliams Corner Wine © 2017. 1112 A Market St # Ste B22,
                Charlottesville, CA 45565
              </p>
            </div>
            <div>
              <p className="font16 font-medium text-black mb-2">News letter</p>
              <div className="p-r">
                <input
                  type="text"
                  placeholder="Enter your email address"
                  className="form-control"
                />
                <img src={mail} alt="" className="mail-icon" />
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-between flex-wrap py-4">
          <p className="bottom-gradient">contact@lovewithbenefits</p>
          {/* <p className="bottom-gradient">(123) 456-7890</p> */}
          <p className="rights">
            © {currentYear} Love With Benefits. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};
